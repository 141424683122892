import React from "react";
import { Link } from "react-router-dom";
import Navlight from "../components/navlight";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

// import Team from "../components/team";
import ContactUs from "../components/contactUs";
import misyon from "../assets/images/misyon.jpeg";
import vizyon from "../assets/images/vizyon.jpeg";
import ilke_prensip from "../assets/images/ilke_prensip.jpeg";
import otizm_meclis from "../assets/images/otizm_meclis.jpg";
import image from "../assets/images/hcrh6317-711x948.jpg";

export default function AboutUs() {
  return (
    <>
      <Navlight />
      <section
        className="relative table w-full py-32 lg:py-40 bg-[url('../../assets/images/bg-inner.jpg')] bg-no-repeat bg-cover"
        style={{ backgroundPosition: "center" }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/80 to-black"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-3 text-3xl leading-normal font-medium text-white">
              Hakkımızda
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="tracking-[0.5px] mb-0 inline-block">
            <li className="inline-block capitalize text-[14px] duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Beş Nokta Otizm Yaşam Derneği</Link>
            </li>
            <li className="inline-block text-[18px] text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="mdi mdi-chevron-right align-middle"></i>
            </li>
            <li
              className="inline-block capitalize text-[14px] duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Hakkımızda
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-5">
              <div className="relative">
                <img
                  src={image}
                  className="rounded-lg shadow-lg relative"
                  alt=""
                />
              </div>
            </div>

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h3 className="mb-4 md:text-2xl text-xl font-medium">
                  Beş Nokta Otizm Yaşam Derneği
                </h3>

                <p
                  className="dark:text-white max-w-2xl mx-auto"
                  style={{ lineHeight: 2, textAlign: "justify" }}
                >
                  <strong>Beş Nokta Otizm Yaşam Derneği</strong> otizmle ilgili
                  gönüllülük ve farkındalık esasına dayanan hak mücadelesi
                  temelli bir sivil toplum örgütü ’dür. Otizmli bireylere ve
                  ailelerine eğitim destek ve bilgi vermeyi hedeflemiş uzman
                  eğitimci ve meslek elemanları topluluğundan oluşmuştur. Başta
                  İstanbul olmak üzere tüm Türkiye’de Otizmli bireyler ve
                  aileleri ile akademik, sosyal, gelişimsel ve bilgi
                  entegrasyonunun sağlanması için çalışan derneğimiz Otizmle (
                  Diğer tüm zihinsel engel grupları ile Down sendromu dahil )
                  ilgili ulusal politikaların belirlenmesinde ’de aktif rol
                  oynamaktadır. İşte bu nedenle Otizmli bireyler, aileleri ve
                  tüm toplum için diyoruz ki; Uzun yollar ilk adımla başlar bu
                  yolda hep birlik de yürüyelim….
                </p>

                <div className="relative mt-8">
                  Beş Nokta Otizm Yaşam Derneği (BENOYDER) <br />
                  Kuruluş tarihi: 17 KASIM 2015
                </div>
                <div className="relative mt-8">
                  <img
                    src={otizm_meclis}
                    alt="Benoyder"
                    style={{ width: "100px !important" }}
                    className="h-auto rounded-lg shadow-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Team /> */}

        <section className="relative md:py-24 py-16">
          <div className="container relative">
            <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
              <div className="lg:col-span-6">
                <div className="relative">
                  <img
                    src={misyon}
                    className="rounded-lg shadow-lg relative"
                    alt=""
                    width={700}
                  />
                </div>
              </div>

              <div className="lg:col-span-6">
                <div className="relative">
                  <img
                    src={vizyon}
                    className="rounded-lg shadow-lg relative"
                    alt=""
                    width={700}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="lg:col-span-5">
          <div className="relative">
            <div className="relative flex justify-center">
              <img
                src={ilke_prensip}
                className="rounded-lg shadow-lg"
                alt=""
                width={700}
              />
            </div>
          </div>
        </div>
        <ContactUs />
      </section>

      <Footer />
      <Switcher />
    </>
  );
}
